import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={`navbar ${menuOpen ? 'menu-open' : ''}`} style={{ position: "relative", zIndex: 2 }}>
      <div className="navbar-container">
        <NavLink to="/" className="navbar-logo">
          Daksh Gandhi
        </NavLink>
        <div className="menu-icon" onClick={toggleMenu}>
          {menuOpen ? <FaTimes /> : <FaBars />}
        </div>
        <ul className={menuOpen ? 'nav-links active' : 'nav-links'}>
          <li>
            <NavLink to="/" end className="nav-item" onClick={closeMenu}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/experience" className="nav-item" onClick={closeMenu}>
              Experience
            </NavLink>
          </li>
          <li>
            <NavLink to="/projects" className="nav-item" onClick={closeMenu}>
              Projects
            </NavLink>
          </li>
          <li>
            <NavLink to="/resume" className="nav-item" onClick={closeMenu}>
              Resume
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
