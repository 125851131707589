import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './ContributionGraph.css';

const ContributionGraph = ({ username }) => {
  const [contributions, setContributions] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchContributions = async () => {
      try {
        const response = await axios.get(
          `https://github-contributions-api.jogruber.de/v4/${username}`
        );
        setContributions(response.data.contributions);
      } catch (error) {
        console.error('Error fetching contributions:', error);
        setError('Failed to load contributions.');
      }
    };

    fetchContributions();
  }, [username]);

  if (error) {
    return <p>{error}</p>;
  }

  if (contributions.length === 0) {
    return <p></p>;
  }

  const contributionsFrom2024 = contributions.filter((day) => {
    const date = new Date(day.date);
    return date.getFullYear() >= 2024;
  });

  if (contributionsFrom2024.length === 0) {
    return <p>No contributions for 2024.</p>;
  }

  const maxCount = Math.max(...contributionsFrom2024.map((day) => day.count));

  const contributionsWithIntensity = contributionsFrom2024.map((day) => {
    let intensity = 0;
    if (day.count > 0) {
      const ratio = day.count / maxCount;
      if (ratio >= 0.75) {
        intensity = 4;
      } else if (ratio >= 0.5) {
        intensity = 3;
      } else if (ratio >= 0.25) {
        intensity = 2;
      } else {
        intensity = 1;
      }
    }
    return { ...day, intensity };
  });

  const getFirstSundayOfYear = (year) => {
    const date = new Date(year, 0, 1);
    const dayOfWeek = date.getDay();
    const diff = (7 - dayOfWeek) % 7;
    date.setDate(date.getDate() + diff);
    return date;
  };

  const startDate = getFirstSundayOfYear(2024);

  const weeks = [];
  contributionsWithIntensity.forEach((day) => {
    const date = new Date(day.date);
    const dayOfWeek = date.getDay();
    const timestamp = date.getTime();

    const daysDifference = Math.floor(
      (timestamp - startDate.getTime()) / (24 * 60 * 60 * 1000)
    );
    const weekIndex = Math.floor(daysDifference / 7);

    if (weekIndex >= 0) {
      if (!weeks[weekIndex]) {
        weeks[weekIndex] = Array(7).fill(null);
      }
      weeks[weekIndex][dayOfWeek] = day;
    }
  });

  const transposedDays = [];
  for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
    const weekDays = [];
    for (let weekIndex = 0; weekIndex < weeks.length; weekIndex++) {
      const day = weeks[weekIndex][dayIndex];
      if (day) {
        weekDays.push(day);
      } else {
        weekDays.push({ count: 0, date: '', intensity: 0 });
      }
    }
    transposedDays.push(weekDays);
  }

  const monthLabels = [];
  let lastMonth = null;

  weeks.forEach((week, weekIndex) => {
    const firstDayOfWeek = week.find((day) => day && day.date);
    if (firstDayOfWeek) {
      const date = new Date(firstDayOfWeek.date);
      const month = date.getMonth();
      const dayOfMonth = date.getDate();

      if (month !== lastMonth && dayOfMonth <= 7) {
        monthLabels.push({ month, index: weekIndex });
        lastMonth = month;
      }
    }
  });

  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  return (
    <div className="contribution-graph-container">
      <div className="contribution-graph-card">
      <h2>
          <a
            href={`https://github.com/${username}`}
            target="_blank"
            rel="noopener noreferrer"
            className="github-link"
          >
            My GitHub Contributions
          </a>
        </h2>     
        <div className="graph-container">
          <div className="month-labels">
            <div className="day-label-placeholder"></div>
            {weeks.map((_, weekIndex) => {
              const monthLabel = monthLabels.find((label) => label.index === weekIndex);
              return (
                <div key={weekIndex} className="month-label">
                  {monthLabel ? monthNames[monthLabel.month] : ''}
                </div>
              );
            })}
          </div>
          <div className="graph-content">
            {transposedDays.map((weekDays, dayIndex) => (
              <div key={dayIndex} className="row">
                {weekDays.map((day, weekIndex) => (
                  <div
                    key={weekIndex}
                    className={`day intensity-${day.intensity}`}
                    title={`${day.date}: ${day.count} contributions`}
                  ></div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContributionGraph;
