import React from 'react';
import './Resume.css';

function Resume() {
  const resumeUrl = '/Daksh_Gandhi_Resume.pdf';

  return (
    <div className="resume-container" style={{ position: "relative", zIndex: 1 }}>
      <iframe
        src={resumeUrl}
        title="Resume"
        className="resume-iframe"
      >
        <p>
          Your browser does not support iframes.
          <a href={resumeUrl}>Download the PDF</a>.
        </p>
      </iframe>
    </div>
  );
}

export default Resume;
