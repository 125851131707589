import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Experience from './components/Experience';
import ProjectCards from './components/Projects';
import Hero from './components/Hero';
import Skills from './components/Skills';
import Resume from './components/Resume';
import ParticlesBackground from './components/ParticlesBackground';
import Contact from './components/Contact';
import FloatingImages from './components/FloatingImages';
import { Analytics } from "@vercel/analytics/react";
import { FaPaw, FaSnowflake } from 'react-icons/fa';
import './App.css';
import ContributionGraph from './components/ContributionGraph';

function App() {
  const [showParticles, setShowParticles] = useState(() => {
    const saved = localStorage.getItem('showParticles');
    return saved !== null ? JSON.parse(saved) : false;
  });

  const handleToggle = () => {
    setShowParticles(prevState => {
      localStorage.setItem('showParticles', JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    <Router>
      {showParticles ? (
        <FloatingImages style={{ position: "fixed", zIndex: -1 }} />
      ) : (
        <ParticlesBackground style={{ position: "fixed", zIndex: -1 }} />
      )}
      <Navbar />
      <Analytics />
      <button className="floating-button" onClick={handleToggle} title="Toggle Background">
        {showParticles ? <FaSnowflake/> : <FaPaw/>}
      </button>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <ContributionGraph username="dakshg3" />
              <Skills />
              <Contact />
            </>
          }
        />
        <Route path="/experience" element={<Experience />} />
        <Route path="/projects" element={<ProjectCards />} />
        <Route path="/resume" element={<Resume />} />
      </Routes>
    </Router>
  );
}

export default App;
