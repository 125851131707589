import React from 'react';
import './Hero.css';
import heroImage from '../assets/images/heroImage.jpeg';

function Hero() {
  return (
    <section className="hero" id="home">
      <div className="hero-content">
        <div className="hero-image">
          <img src={heroImage} alt="Your Name" />
        </div>
        <h1 className="hero-name"> Hey There!, I am Daksh Gandhi</h1>
        <p className="hero-description">A full stack developer with a Master’s in Computer Science from <span class="uiuc">UIUC</span> and 3+ years of 
        experience specializing in cloud computing, distributed systems, and software automation. </p>
        <a
          href="/Daksh_Gandhi_Resume.pdf"
          className="btn"
          download
        >
          Download Resume
        </a>
      </div>
    </section>
  );
}

export default Hero;
