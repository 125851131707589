// src/components/Contact.js
import React from 'react';
import './Contact.css';
import { FaEnvelope, FaLinkedin, FaGithub } from 'react-icons/fa';

function Contact() {
  return (
    <div className="contact-page">
      <h2 className="contact-heading">Contact Me</h2>
      <div className="contact-links">
        <a href="mailto:dakshg3@illinois.edu" className="contact-link">
          <FaEnvelope className="contact-icon" />
          <span>Email</span>
        </a>
        <a
          href="https://www.linkedin.com/in/dakshgandhi1"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
        >
          <FaLinkedin className="contact-icon" />
          <span>LinkedIn</span>
        </a>
        <a
          href="https://github.com/dakshg3"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
        >
          <FaGithub className="contact-icon" />
          <span>GitHub</span>
        </a>
      </div>
    </div>
  );
}

export default Contact;
