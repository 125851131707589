import React from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

import cat from '../assets/images/cat.png';


function FloatingImages() {
  const particlesInit = async main => {
    await loadFull(main);
  };

  const particlesLoaded = container => {
    // console.log(container);
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      style={{
        position: "fixed",
        // add zindex
        zIndex: -1,

      }}
      options={{
        fullScreen: { enable: true },
        particles: {
          number: {
            value: 400,
            density: {
              enable: true,
              area: 800,
            },
          },
          color: {
            value: '#ffffff',
          },
          shape: {
            type: "image",
            image: [
              {
                src: cat,
                width: 100,
                height: 100,
              },
            ],
          },
          opacity: {
            value: 0.5,
            random: true,
          },
          size: {
            value: 10,
            random: true,
          },
          move: {
            enable: true,
            speed: 1,
            direction: 'none',
            random: false,
            straight: false,
            outModes: 'out',
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
          links: {
            enable: false,
          },
        },
        interactivity: {
          events: {
            onHover: {
              enable: true,
              mode: 'attract',
            },
            resize: true,
          },
          modes: {
            attract: {
              distance: 150,
              duration: 0.4,
              factor: 1.5,
            },
          },
        },
        retina_detect: true,
        background: {
          color: 'transparent',
        },
      }}
    />
  );
}

export default FloatingImages;
