import React from 'react';
import './Skills.css';

function Skills() {
    const skills = [
        'Programming Languages (Python, Bash, C/C++, Java, Javascript)',
        'Python (Django, Flask, Robot Framework)',
        'Networking and Linux',
        'API (REST API, Postman)',
        'DBMS (SQL and NoSQL)',
        'Automation (Selenium, PlayWright, Jmeter, Jenkins CI)',
        'Git',
        'Next.js',
        'React',
        'Android',
        'Docker',
        'Hadoop',
        'AWS',
        'Azure',
        'Kubernetes',
        'System Design',
        'Distributed Systems',
        ];

  return (
    <section className="skills">
      <div className="skills-content">
        <h2 className="skills-heading">Technical Skills</h2>
        <div className="skills-list">
          {skills.map((skill, index) => (
            <div className="skill-item" key={index}>
              {skill}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Skills;
